@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap);
h1,
h4,
p,
.MuiFormControlLabel-label {
    /* font-family: Lato; */
    color: #313de9;
}

.MuiButton-label {
    font-weight: bold;
}

.gm-style .gm-style-iw-d {
    overflow: hidden;
}

.gm-style .gm-style-iw-c {
    position: absolute;
    /* box-sizing: border-box; */
    overflow: hidden;
    top: 0;
    left: 0;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    background-color: #ffffff;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

#contextmenu{
    display:none;
    position:fixed;
    left:0px;
    top:0px;
    width:200px;
    padding: 0;
    border:1px solid #000;
    background-color:rgb(218, 209, 209);
}
#contextmenu li{
    cursor:pointer;
}

.inputGroupTitle {
    text-align: center;
    height: 40px;
    padding: 0px;
    color: black;
    font-weight: bold;
    font-size: 16pt;
    background: rgb(217, 217, 217);
    border: solid 1px black;
}

.h1 {
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 28pt;
}

.table {
    border-collapse: collapse;
    border: solid 1.5px black;
    margin: auto;
    width: 90%;
}

.result {
    font-size: 36pt;
    padding: 3px;
    color: black;
    width: 34%;
    height: 60px;
    border: solid 1.5px black;
    text-align: left;
}

.itemNameCell {
    font-size: 14pt;
    padding: 3px;
    color: black;
    width: 16%;
    background: rgb(242, 242, 242);
    height: 20px;
    border: solid 1.5px black;
}

.itemValueCell {
    font-size: 14pt;
    padding: 3px;
    color: black;
    width: 34%;
    height: 20px;
    border: solid 1.5px black;
}

.itemFloorCell {
    white-space: nowrap;
    overflow: hidden;
    width: 30vw;
    display: inline-block;
}

.shoukenGroup {
    text-align: center;
    width: 50%;
    height: 35px;
    padding: 0px;
    color: black;
    font-size: 14pt;
    background: rgb(242, 242, 242);
    border: solid 1px black;
}

.shoukenGroupSub {
    text-align: center;
    width: 10%;
    height: 35px;
    padding: 0px;
    color: black;
    font-size: 14pt;
    background: rgb(242, 242, 242);
    border: solid 1px black;
}

.shoukenName {
    font-size: 14pt;
    padding: 3px;
    color: black;
    width: 50%;
    height: 20px;
    background: rgb(242, 242, 242);
    border: solid 1.5px black;
}

.shoukenValue {
    font-size: 14pt;
    padding: 3px;
    color: black;
    width: 10%;
    height: 20px;
    border: solid 1.5px black;
}

.height-90 {
    height: 90px;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.attention {
    font-size: 14pt;
    position: relative;
    z-index: -1000;
    left: 5%;
    margin: auto;
    color: black;
}

.space {
    height: 20px;
}

.footerSpace {
    height: 60px;
}

.date {
    font-size: 14pt;
    text-align: right;
    position: relative;
    right: 5%;
    color: black;
}

.pageNumber {
    font-size: 14pt;
    text-align: center;
    color: black;
}

.border-bottom {
    border-bottom: solid 2px black;
    margin-bottom: -3px;
}

.border-red {
    border-bottom: solid 2px red;
}

.centering-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 434px;
}

.centering-items-row {
    display: flex;
    flex-direction: row;
}

.head_title {
    margin-bottom: 32px;
    height: 16px;
    text-align: center;
    color: #557ee3;
    font-size: 16px;
    font-weight: 700;
}

.sp_submit_btn {
    height: 40px;
    border-radius: 20px;
    border: none;
    margin: 24px 12px;
    margin-top: 32px;
    width: 440px;
}

.submit_btn {
    background-color: #557ee3;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset_btn {
    height: 36px;
    padding-top: 10px;
    margin-bottom: 7px;
    display: flex;
    cursor: pointer;
}

.reset_btn_text {
    color: #2e64ed;
}

.next_btn {
    margin-left: 6px;
    width: 20px;
    height: 28px;
}

.back_btn {
    margin-right: 6px;
    width: 20px;
    height: 28px;
}

.back_btn {
    margin-right: 6px;
    width: 20px;
    height: 28px;
}

.head_text {
    margin-bottom: 24px;
    text-align: center;
    color: #4F4F4F;
    font-size: 14px;
    font-weight: 400;
    line-height: 175%;
}

/* モーダル用 */
.react-responsive-modal-root {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1500 !important;
}
.react-responsive-modal-container {
    pointer-events: none;
}

.react-responsive-modal-closeButton {
    display: none !important;
}

.react-responsive-modal-modal {
    font-family: 'Noto Sans JP';
    border-radius: 8px;
    width: 332px;
    height: 273px;
    text-align: center !important;
    pointer-events: auto !important;
}
.modal_header {
    margin-top: 16px;
    margin-bottom: 24px;
    color: #557eef;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
}
.modal_header_reset {
    font-size: 20px;
}
.modal_header_logout {
    margin-top: 16px;
    margin-bottom: 8px;
    color: #557eef;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
}
.modal_header_explanation {
    font-family: 'Noto Sans JP';
    margin-top: 24px;
}
.modal_text {
    color: #4f4f4f;
    margin-left: 12px;
    margin-right: 12px;
    font-size: 16px;
    line-height: 175%;
}
.modal_text_reset {
    font-size: 14px;
}
.modal_explanation_text {
    margin-top: 24px;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 175%;
    letter-spacing: 0.6px;
    color: #4F4F4F;
    text-align: left !important;
}
.explanation_text_important {
    color: #E54E75;
}
.modal_explanation_detail {
    width: 283px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    padding: 16px 24px;
    border-radius: 12px;
    position: relative;
}
.modal_explanation_detail::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(336.24deg, #6FCCD9 2.89%, #6289E6 97.98%);
    border-radius: 12px;
    content: "";
    z-index: 1;
}
.modal_explanation_detail::after{
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 12px;
    background: #fff;
    content: "";
    z-index: 2;
}
.modal_explanation_detail_contents {
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.modal_explanation_seconds_content {
    margin-top: 8px;
}
.modal_explanation_detail_content_logo {
    margin-right: 4px;
}
.modal_explanation_detail_text {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4F4F4F;
}
.modal_close_btn {
    border: none;
    color: #557ee3;
    font-weight: 400;
    font-size: 14px;
    margin-top: 45px;
    background-color: #ffffff;
    cursor: pointer;
}

.modal_close_btn_reset {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal_explanation_close_btn {
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    margin-top: 30px;
}
.modal_explanation_close_btn_link {
    display: flex;
    color: #557ee3;
    cursor: pointer;
}
.modal_explanation_close_text {
    margin-left: 6px;
    line-height: 20px;
}
.modal_yes_btn {
    width: 284px;
    height: 40px;
    margin: 0 auto;
    background-color: #557EE3;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    margin-top: 24px;
    cursor: pointer;
}
.modal_no_btn {
    width: 284px;
    height: 40px;
    margin: 0 auto;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    border: 1px solid #749BFF;
    box-sizing: border-box;
    color: #557EE3;
    font-size: 16px;
    font-weight: 700;
    margin-top: 24px;
    cursor: pointer;
}
.modal_submit_btn {
    background-color: #557ee3;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 284px;
    height: 30px;
    border-radius: 20px;
    padding-top: 10px;
    border: none;
    margin: 32px auto;
    line-height: 18px;
}
.pac-container {
  margin-top: 10px;
}

